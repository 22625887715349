import {AppManifestBuilder} from '@wix/yoshi-flow-editor'
import {EVENTS_WIDGET_ID, ExperimentNames} from '@wix/wix-events-commons-statics'
import {SdkWrapper} from '../services/sdk'
import {ARTICLES} from '../../components/widget/Settings/constants'
import {TAB} from '../../commons/constants/navigation'
import {EVENTS_APP_DEF_ID} from '../constants'

export const widgetGfppBuilder =
  ({t, experiments, editorSdk, appToken}) =>
  async (appManifestBuilder: AppManifestBuilder) => {
    const newWidgetSettingsGfpp = experiments[ExperimentNames.NewWidgetSettingsGFPP]?.toString()
    const isNewGfppButtons = ['display', 'show'].includes(newWidgetSettingsGfpp)
    const sdkWrapper = SdkWrapper(editorSdk, appToken)

    if (isNewGfppButtons) {
      const widgetSettingsUrl = await sdkWrapper.getSettingsUrl(EVENTS_WIDGET_ID, EVENTS_APP_DEF_ID)

      return appManifestBuilder.configureWidget(EVENTS_WIDGET_ID, widgetBuilder =>
        widgetBuilder
          .gfpp()
          .set('mainAction2', {
            label: t(
              newWidgetSettingsGfpp === 'display'
                ? 'gfppWidgetSettingsButtonDisplayEvents'
                : 'gfppWidgetSettingsButtonShowEvents',
            ),
            onClick: event => {
              sdkWrapper.openSettings(
                {
                  title: t('widgetSettingsTitle'),
                  url: widgetSettingsUrl,
                  componentRef: event.detail.componentRef,
                  initialData: {
                    tab: 'events',
                    myEventsText: newWidgetSettingsGfpp === 'display' ? 'display' : 'show',
                  },
                  helpId: ARTICLES[TAB.MY_EVENTS],
                },
                {preloader: true},
              )
            },
          })
          .set('settings', {
            onClick: event =>
              sdkWrapper.openSettings(
                {
                  title: t('widgetSettingsTitle'),
                  url: widgetSettingsUrl,
                  componentRef: event.detail.componentRef,
                  initialData: {
                    myEventsText: newWidgetSettingsGfpp === 'display' ? 'display' : 'show',
                  },
                  helpId: ARTICLES[TAB.MAIN],
                },
                {preloader: true},
              ),
          }),
      )
    }

    return appManifestBuilder
  }

import {EditorSDK} from '@wix/platform-editor-sdk'
import {ProgressBarConfig} from '../constants'
import {waitALittle} from './wait-a-little'

export interface ProgressBar {
  open: (config: ProgressBarConfig) => void
  close: () => Promise<void>
}

export const SilentProgressBar = (): ProgressBar => ({
  open: () => {},
  close: () => Promise.resolve(),
})

export const SdkProgressBar = (sdk: EditorSDK, t: Function, appToken: string): ProgressBar => {
  let progressBarPromise: Promise<void>

  return {
    open: ({title, step1, step2, step3, timeBetweenSteps}: ProgressBarConfig) => {
      if (!progressBarPromise) {
        progressBarPromise = new Promise(async resolve => {
          await sdk.editor.openProgressBar(appToken, {
            title: t(title),
            totalSteps: 3,
            currentStep: 1,
            stepTitle: t(step1),
          })
          await waitALittle(timeBetweenSteps)
          await sdk.editor.updateProgressBar(appToken, {
            currentStep: 2,
            stepTitle: t(step2),
          })
          await waitALittle(timeBetweenSteps)
          await sdk.editor.updateProgressBar(appToken, {
            currentStep: 3,
            stepTitle: t(step3),
          })
          await waitALittle(800)
          resolve()
        })
      }
    },
    close: async () => {
      if (progressBarPromise) {
        await progressBarPromise
        sdk.editor.closeProgressBar(appToken, {})
        progressBarPromise = null
      }
    },
  }
}
